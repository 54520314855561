import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.symbol-cards-group');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((block) => {
      const carousel = block.querySelector('.symbol-cards-group__carousel');
      const slides = carousel.querySelectorAll('.swiper-slide');

      if (3 < slides.length) {
        new Swiper(carousel, {
          spaceBetween: 6,
          slidesPerView: 1.1,

          breakpoints: {
            460: {
              spaceBetween: 6,
              slidesPerView: 1.3,
            },
            720: {
              spaceBetween: 6,
              slidesPerView: 2.3,
            },
            1120: {
              spaceBetween: 12,
              slidesPerView: 3.05,
            },
            1360: {
              spaceBetween: 12,
              slidesPerView: 'auto',
            },
          },

          pagination: {
            el: carousel.querySelector(
              '.symbol-cards-group__carousel__pagination'
            ),
            clickable: true,
          },
        });
      } else {
        block.classList.add('grid-view');
      }
    });
  }
});
